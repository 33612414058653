import React, { useState, useEffect } from 'react';
import icsToJson from "ics-to-json-extended";
import { format } from 'date-fns';

const convert = async (icsFileUrl) => {
  const icsRes = await fetch(icsFileUrl);
  const icsData = await icsRes.text();
  const data = icsToJson(icsData);
  return data;
};

export default function SlideCalendar({ slide, index }) {
  const layout = slide.acf_fc_layout;
  const slideClass = 'slide-' + layout;
  const icsFileUrl = slide.ical_url;
  const backgroundImage = slide.background_image;
  const [dates, setDates] = useState([]);
  const currentDate = format(new Date(), 'yyyyMMdd');
  const futureDates = dates.filter((date) => format(new Date(date.startDate), 'yyyyMMdd') >= currentDate).slice(0, 5);
  useEffect(() => {
    convert(icsFileUrl).then((value) => setDates(value));
  }, []);

  return (
    <swiper-slide type={layout} class={slideClass}>
      <div className="row row--full slide-body" style={{
        backgroundImage: `url(${backgroundImage})`
      }}>
        <div className="slide-content">
          <ol className="dates-list">
            {futureDates && futureDates.map((date, index) => (
              <React.Fragment key={`date-${index}`}>
                <li className={`dates-list__item${format(new Date(date.startDate), 'yyyyMMdd') === currentDate ? ' today' : ''}`}>
                  {format(new Date(date.startDate), 'dd.MM.yyyy')} {date.summary}{date.location && `, ${date.location}`}
                </li >
              </React.Fragment>
            ))}
          </ol>
        </div>
      </div>
    </swiper-slide>
  )
}
