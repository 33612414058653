import React from 'react'

export default function SlideImage({ slide }) {
  const image = slide.image.url;
  const layout = slide.acf_fc_layout;
  const slideClass = 'slide-' + layout;

  return (
    <swiper-slide type={layout} class={slideClass} key={Math.random()}>
      <div className="slide-body">
        <div className="slide-content">
          <img src={image} alt='' />
        </div>
      </div>
    </swiper-slide>
  )
}
