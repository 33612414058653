import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

export default function SlideWelcome({ slide }) {
  const layout = slide.acf_fc_layout;
  const backgroundImage = slide.background_image;
  const slideClass = 'slide-' + layout;
  const slideDate = new Date(slide.datum);
  const visitors = slide.visitor;
  const [visitorStacks, setVisitorStacks] = useState([]);
  const [currentIndex] = useState(0);
  const visitorsMaxCount = 4;

  useEffect(() => {
    VisitorList();
  }, [currentIndex]);

  function VisitorList() {
    const visitorArr = Array.isArray(visitors) ? [...visitors] : [];
    const visitorItem = visitorArr.map((visitor, index) => (
      <div key={index} className='visitor'>
        <div className='visitor-logo'>
          <img src={visitor.logo} alt={visitor.company} />
        </div>
        <div className='visitor-text'>
          <span>{visitor.company}<br /></span>
          {visitor.names.split("\n").map((name, index) => (
            <span key={index}>{name}<br /></span>
          ))}
        </div>
      </div>
    ));

    const newVisitorStacks = [];
    for (let i = 0; i < visitorItem.length; i += visitorsMaxCount) {
      newVisitorStacks.push(visitorItem.slice(i, i + visitorsMaxCount));
    }
    setVisitorStacks(newVisitorStacks);
  }

  function VisitorStack(props) {
    const { stack } = props;
    return <>{stack}</>;
  }

  return (
    <>
      {visitorStacks.map((stack, index) => (
        <swiper-slide key={Math.random()} type={layout} class={slideClass}>
          <div className="row row--full slide-body" style={{
            backgroundImage: `url(${backgroundImage})`
          }}>
            <div className="slide-header">
              <h1>Wir begrüßen herzlich am {format(slideDate, 'dd.MM.yyyy')} in Dortmund.</h1>
            </div>
            <div className="slide-content">
              <VisitorStack key={index} stack={stack} />
            </div>
          </div>
        </swiper-slide>
      ))}
    </>
  )
}
