import React from 'react'

export default function SlideStart({ slide }) {
  const image = slide.image;
  const layout = 'image';
  const slideClass = 'slide-' + layout;

  return (
    <swiper-slide type={layout} class={slideClass}>
      <div className="slide-body">
        <div className="slide-content">
          <img src={image} alt='' />
        </div>
      </div>
    </swiper-slide>
  )
}
