import React, { useState, useEffect } from 'react';

export default function SlideLogos({ slide }) {
  const [logoStacks, setLogoStacks] = useState([]);

  const logos = slide.logos;
  const layout = slide.acf_fc_layout;
  const logoRows = slide.rows;
  const logoCols = slide.columns;
  const logoPadding = slide.padding;
  const slideClass = 'slide-' + layout;
  const logosMaxCount = logoRows * logoCols;

  useEffect(() => {
    setLogoStacks(generateLogoStacks());
  }, []);

  function generateLogoStacks() {
    const logoItem = logos.map((logo, index) => (
      <div key={index} className="logo-list__item" style={{ padding: `${logoPadding}vh` }}>
        <img src={logo} alt='' />
      </div>
    ));
    const newLogoStacks = [];
    for (let i = 0; i < logoItem.length; i += logosMaxCount) {
      newLogoStacks.push(logoItem.slice(i, i + logosMaxCount));
    }
    return newLogoStacks;
  }

  function LogoStack(props) {
    const { stack } = props;
    return <>{stack}</>;
  }

  const gridStyles = {
    gridTemplateColumns: `repeat(${logoCols}, 1fr)`,
    gridTemplateRows: `repeat(${logoRows}, 1fr)`,
  };

  return (
    <>
      {logoStacks.map((stack, index) => (
        <swiper-slide key={index * Math.random()} type={layout} class={slideClass}>
          <div className="slide-body">
            <div className="slide-header">
              <h1>
                Mit starken Marken im Verbund – unsere Lieferanten
              </h1>
            </div>
            <div className="slide-content" style={gridStyles}>
              <LogoStack key={stack[0].url} stack={stack} />
            </div>
          </div>
        </swiper-slide>
      ))}
    </>
  );
}
