import './scss/App.scss';
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { register } from 'swiper/element/bundle';

// Slides
import SlideStart from './components/Slide-Start';
import SlideWelcome from './components/Slide-Welcome';
import SlideImage from './components/Slide-Image';
import SlideVideo from './components/Slide-Video';
import SlideLogos from './components/Slide-Logos';
import SlideCalendar from './components/Slide-Calendar';

register();

function App() {
  const [data, setData] = useState([]);
  const [previousData, setPreviousData] = useState(null);
  const [config, setConfig] = useState(null);
  const swiperRef = useRef(null);

  /**
   * Get config.
   */
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('./config-dev.json');
        const configData = await response.json();
        setConfig(configData);
      } catch (error) {
        console.error('Fehler beim Laden der config.json:', error);
      }
    };

    fetchConfig();
  }, []);

  /**
   * Get data.
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios(config?.apiPath);
        const newData = result.data;

        if (!previousData || JSON.stringify(newData) !== JSON.stringify(previousData)) {
          setData(newData);
          setPreviousData(newData);
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
      }
    };

    if (config) {
      fetchData();

      // const interval = setInterval(() => {
      //   fetchData();
      // }, 5000);

      // return () => clearInterval(interval);
    }
  }, [config, previousData]);

  // Init slider when data is loaded.
  useEffect(() => {
    if (swiperRef.current && data) {
      swiperRef.current.initialize();

      const allSlides = document.querySelectorAll('swiper-slide');
      const lastSLide = [...allSlides].pop();

      if (lastSLide.classList.contains('slide-video')) {
        swiperRef.current.addEventListener('reachend', () => {
          const videoElement = lastSLide.querySelector('video');
          videoElement.addEventListener("ended", (event) => {
            location.reload();
          });
        });
      } else {
        swiperRef.current.addEventListener('reachend', () => {
          setTimeout(() => {
            location.reload();
          }, duration);
        });
      }
    }
  }, [data]);

  /**
   * Loading state.
   */
  if (!config || !data.settings || !data.slides) {
    return <div>Loading...</div>;
  }

  /**
   * Settings & Slides.
   */
  const { settings, startslide, slides } = data;
  const duration = settings.duration * 1000;
  const speed = settings.speed;

  return (
    <swiper-container
      ref={swiperRef}
      slidesPerView={1}
      navigation="false"
      keyboard="true"
      autoplay="true"
      autoplay-delay={duration}
      autoplay-disable-on-interaction="false"
      effect="fade"
      speed={speed}
      init="false"
    >
      {startslide.show ? (
        <SlideStart slide={startslide} />
      ) : null}
      {slides.map((slide, index) => (
        <>
          {slide.acf_fc_layout === 'welcome' ? (
            <SlideWelcome key={`slide-${slide.acf_fc_layout}-${index}`} slide={slide} />
          ) : null}
          {slide.acf_fc_layout === 'image' ? (
            <SlideImage key={`slide-${slide.acf_fc_layout}-${index}`} slide={slide} />
          ) : null}
          {slide.acf_fc_layout === 'video' ? (
            <SlideVideo key={`slide-${slide.acf_fc_layout}-${index}`} swiperRef={swiperRef} slideDuration={duration} slide={slide} />
          ) : null}
          {slide.acf_fc_layout === 'logos' ? (
            <SlideLogos key={`slide-${slide.acf_fc_layout}-${index}`} slide={slide} />
          ) : null}
          {slide.acf_fc_layout === 'calendar' ? (
            <SlideCalendar key={`slide-${slide.acf_fc_layout}-${index}`} slide={slide} />
          ) : null}
        </>
      ))}
    </swiper-container>
  );
}

export default App;
