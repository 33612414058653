import React, { useEffect, useRef } from 'react'

export default function SlideImage({ swiperRef, slideDuration, slide }) {
  const videoRef = useRef(null);
  const video = slide.video.url;
  const videoDuration = slide.video.duration * 1000;
  const layout = slide.acf_fc_layout;
  const slideClass = 'slide-' + layout;

  useEffect(() => {
    // Add eventlistener to stop slider autoplay and start video
    swiperRef.current.addEventListener('slidechange', event => {
      const active = event.detail[0].visibleSlides[0].classList.contains('slide-video');

      if (active) {
        const swiper = swiperRef.current.swiper;

        swiper.autoplay.stop();
        videoRef.current.play();

        // Resume slider autoplay after video ended.
        setTimeout(() => {
          swiper.autoplay.start();
        }, videoDuration - slideDuration)
      }
    });
  }, [slideDuration])

  return (
    <swiper-slide type={layout} class={slideClass}>
      <div className="slide-body">
        <div className="slide-content">
          <video
            ref={videoRef}
            src={video}
            alt=""
            preload='auto'
            controls={false}
            autoPlay={false}
            muted={true}
          />
        </div>
      </div>
    </swiper-slide>
  )
}
